// Marathi
export default{
    form: {
        startSurveyButton: "चला सुरू करूया",
        nextButton: "पुढे",
        submitAnswerButton: "उत्तर सबमिट करा",
        continueButton: "सुरू ठेवा",
        finishButton: "पूर्ण करा",
        headerPercentageCompletion: 'पूर्ण',
        headerQuestionsCompleted: 'प्रश्न',
        headerDefaultErrorMessage: 'अरेरे! काहीतरी चुकलं.',
        brandingPoweredBy: 'द्वारे चालते',
        brandingPromoWhySurvey: 'सर्वेक्षण का करायचं, जेव्हा तुम्ही <b>{product}</b> वापरू शकता?',
        brandingPromoGetStarted: 'आजच सुरू करा!',
        submissionPending: "तुमचं voiceform सबमिट होत आहे. कृपया ब्राउजर बंद करू नका.",
        submissionCanCloseWindow: "तुमचं voiceform यशस्वीरित्या सबमिट झालं आहे. आता तुम्ही विंडो बंद करू शकता 👍",
        mediaAttachmentOverlayCloseButton: "बंद करा",
        expiredFormHeader: "येण्यासाठी धन्यवाद!",
        expiredFormMessage: "असं दिसतंय की हा सर्वेक्षण कालबाह्य झाला आहे. कृपया voiceform तयार करणाऱ्याला संपर्क करा.",
        notPublishedFormHeader: "voiceform प्रकाशित केलेले नाही!",
        notPublishedFormMessage: "असं दिसतंय की हा सर्वेक्षण प्रकाशित केलेला नाही. कृपया voiceform तयार करणाऱ्याला संपर्क करा.",
        notPublishedFormRetryButton: "पुन्हा प्रयत्न करा",
        errorFormHeader: "अरेरे!",
        errorFormMessage: "काहीतरी चुकीचं झालं असावं. कृपया नंतर पुन्हा प्रयत्न करा.",
        errorFormRetryButton: "पुन्हा प्रयत्न करा",
        emptyFormHeader: "येण्यासाठी धन्यवाद!",
        emptyFormMessage: "अरेरे! असं दिसतंय की हा फॉर्म सध्या रिकामा आहे, प्रश्न किंवा भरायची क्षेत्रं नाहीत. गैरसोयीबद्दल आम्ही क्षमस्व आहोत. कृपया या समस्येबद्दल फॉर्म तयार करणाऱ्याला संपर्क करा. तुमच्या समजुतीबद्दल आणि सहकार्याबद्दल धन्यवाद!",

        restoreFormHeader: "असं दिसतंय की तुमचा फॉर्म पूर्ण व्हायचा आहे!",
        restoreFormMessage: "तुम्ही जिथे सोडलं तिथून सुरू करू इच्छिता?",
        restoreFormButton: "फॉर्म सुरू ठेवा",
        restoreFormButtonNew: "पुन्हा सुरू करा",

        audioPermissionHeader: "तुम्ही आवाजाने उत्तर द्यायचं आहे का?",
        audioPermissionMessage: "बोलणं उत्तर देणं जलद आणि सोपं करेल.",
        audioPermissionAcceptButton: "होय, मला पाहिजे",
        audioPermissionDenyButton: "नको, धन्यवाद",
        audioPermissionErrorHeader: "मायक्रोफोन उपलब्ध नाही",
        audioPermissionErrorMessage: "कृपया मायक्रोफोनपर्यंत प्रवेश करण्यासाठी ब्राउजर परवानग्या तपासा.",
        audioPermissionErrorButton: "ठीक आहे",
        audioRecorderFailedUpload: "रेकॉर्डिंग अपलोड करण्यात अयशस्वी",
        silenceDetectorError: "आम्ही तुमचं ऐकू शकत नाही. कृपया तुमच्या मायक्रोफोन सेटिंग्ज तपासा.",
        audioRecorderButtonRecord: "रेकॉर्ड करा",
        audioRecorderButtonRecordMore: "अजून रेकॉर्ड करा",
        voiceResponseSelectionMessage: "तुम्हाला कसं उत्तर द्यायचं आहे ते निवडा...",
        voiceResponseSelectionOrDivider: "किंवा",
        voiceResponseTextInputPlaceholder: "उत्तर टाइप करा",
        voiceResponseTextInputCharacterCounterMinMessage: "कृपया किमान {min} अक्षरं टाइप करा",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} अक्षरं (किमान {min} अक्षरं)",
        voiceResponseTextInputCharacterValidationMessage: "{count} अक्षरं (किमान {min} अक्षरं)",

        // checkbox
        checkboxValidationTooFew: "तुम्हाला किमान {min} पर्याय(स) निवडावे लागतील",
        checkboxValidationTooMany: "तुम्ही जास्तीत जास्त {max} पर्याय(स) निवडू शकता",
        checkboxNoneOfTheAboveOption: "वरचं एकही नाही",
        checkboxInvalidAnswer: "अवैध उत्तर.",

        // datepicker
        datePickerPlaceholder: "तारीख निवडा",

        // dropdown
        // email
        emailLabel: "ईमेल",
        emailInvalid: "अवैध ईमेल.",
        // file-upload
        fileUploadPluginNameCamera: "कॅमेरा",
        fileUploadPluginNameCameraVideo: "व्हिडिओ रेकॉर्ड करा",
        fileUploadPluginScreenCast: "स्क्रीन रेकॉर्ड करा",
        fileUploadVideoImportFilesDefault: 'व्हिडिओ रेकॉर्ड करा किंवा अपलोड करा:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'व्हिडिओ रेकॉर्डिंगचा पर्याय निवडा:',
        fileUploadVideoImportFilesNoCamera: 'स्क्रीन व्हिडिओ रेकॉर्ड करण्यासाठी बटण दाबा',
        fileUploadVideoImportFilesNoScreenCast: 'व्हिडिओ रेकॉर्ड करण्यासाठी बटण दाबा',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'व्हिडिओ रेकॉर्ड करण्यासाठी कोणतेही पर्याय उपलब्ध नाहीत',

        // matrix
        matrixValidationMessage: "उत्तर वैध आहे.",
        matrixRow: "ओळ",
        matrixColumn: "स्तंभ",
        matrixRowRequired: "ओळ {rowTitle} आवश्यक आहे.",
        matrixRadioGroup: "रेडिओ समूह",
        matrixCheckboxGroup: "चेकबॉक्स समूह",
        matrixGroupRequired: "ओळ {rowTitle} साठी. {type} {groupTitle} आवश्यक आहे.",
        matrixColumnRequired: "ओळ {rowTitle} साठी. स्तंभ {columnTitle} आवश्यक आहे.",
        matrixColumnInvalid: "ओळ {rowTitle} साठी. स्तंभ {columnTitle} अवैध आहे.",
        matrixRequired: "आवश्यक आहे.",
        matrixNumericMustBeNumber: "संख्या असली पाहिजे.",
        matrixNumericMustBeGreaterThenMin: "{min} पेक्षा मोठं असलं पाहिजे.",
        matrixNumericMustBeLessThenMax: "{max} पेक्षा कमी असलं पाहिजे.",
        matrixNumericMustBeInteger: "पूर्णांक असावा.",
        matrixNumericInvalidNumber: "अवैध संख्या.",

        // name
        nameLabel: "नाव",
        nameInvalid: "कृपया नाव आणि आडनाव द्या.",

        // nps
        npsNotLikely: "एकदमच कमी शक्यता",
        npsExtremelyLikely: "फारच जास्त शक्यता",

        // numeric input
        numericInputRequired: "आवश्यक आहे.",
        numericInputMustBeNumber: "संख्या असली पाहिजे.",
        numericInputMustBeGreaterThenMin: "{min} पेक्षा मोठं असलं पाहिजे.",
        numericInputMustBeLessThenMax: "{max} पेक्षा कमी असलं पाहिजे.",
        numericInputMustBeInteger: "पूर्णांक असावा.",
        numericInputInvalidNumber: "अवैध संख्या.",
        numericInputPlaceholder: "संख्या लिहा",

        // phone
        phoneInvalid: "फोन नंबर अवैध आहे.",
        phoneCountrySelectorLabel: 'देशाचा कोड',
        phoneCountrySelectorError: 'देश निवडा',
        phoneNumberLabel: 'फोन नंबर',
        phoneExample: 'उदाहरण:',

        // boolean
        booleanYesLabel: "होय",
        booleanNoLabel: "नाही",

        //questionStep
        questionStepAudioQuestionLabel: "ऑडिओ प्रश्न",

        // errors
        invalidPhoneNumber: "{phone} अवैध फोन नंबर आहे.",
        invalidEmail: "{email} अवैध ईमेल पत्ता आहे.",
        questionIsRequired: "प्रश्न आवश्यक आहे.",
        answerIsNotValid: "उत्तर वैध नाही.",
        unfinishedProbingDialogError: "कृपया संवाद पूर्ण करा.",
        cannotResumePartialResponse: "आंशिक प्रतिसाद पुन्हा सुरू करता येत नाही.",
        failedToSubmitForm: "फॉर्म सबमिट करण्यात अयशस्वी. कृपया तुमचं इंटरनेट कनेक्शन तपासा आणि पुन्हा प्रयत्न करा.",

        //language picker
        searchLanguage: "भाषा शोधा",
    }
}
